<template>
  <div class="layout-topbar">
    <router-link to="/app" class="layout-topbar-logo">
      <div class="tw-w-24">
        <img
          alt="Salonial Logo"
          src="~@/assets/images/logo.png"
          class="tw-w-full tw-h-full tw-object-contain"
        />
      </div>
      <span class="tw-text-gray-600">{{ title }}</span>
    </router-link>

    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <ul class="hidden origin-top layout-topbar-menu lg:flex align-items-center">
      <li>
        <span>{{ user ? user.email : '???' }}</span>
      </li>
      <li class="relative">
        <a href="#" class="pl-4" @click.prevent="showNotification">
          <i class="pi pi-bell p-text-secondary" style="font-size: 1.5rem" v-if="!data.length"></i>
          <i class="pi pi-bell p-text-secondary" style="font-size: 1.5rem" v-badge.danger v-else></i>
        </a>
        <div class="menu-overlay absolute" v-show="show_menu">
          <template v-if="data.length">
            <a href="#" class="block item px-3" :class="{'active': !item.is_read}" v-for="(item, index) in data" :key="`_${index}`">
              <div class="px-2 pt-3 py-3">
                <h6 class="tw-font-bold">
                  {{ item.title }}
                </h6>
                <p class="ellipsis-2">
                  {{ item.message }}
                </p>
              </div>
              <Divider class="my-0" />
            </a>
            <h6 class="text-center my-3">
              <a href="notification" class="tw-text-blue-400" @click.prevent="toNotification()">
                Show all
              </a>
            </h6>
          </template>
          <template v-else>
            <div class="py-5 px-4 text-center">
              Empty
            </div>
          </template>
        </div>
      </li>
      <li>
        <button class="p-link layout-topbar-button" @click="submitLogout">
          <i class="pi pi-sign-out"></i>
          <span>Logout</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { useCustomAuth } from '@/composables/auth';

export default {
  setup() {
    const { logout } = useCustomAuth();

    const submitLogout = () => {
      logout();
    };

    return { submitLogout };
  },
  props: ['user'],
  data() {
    return {
      data: [],
      show_menu: false,
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
  },
  mounted () {
    this.getNotifications();
  },
  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event);
    },
    getNotifications() {
      this.$http
        .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/notification/list?type&is_read&limit=5&offset=0`)
        .then(response => {
          this.data = response.data.data.notifications
        })
    },
    showNotification() {
      this.show_menu = !this.show_menu
    },
    toNotification() {
      this.show_menu = false
      this.$router.push({name: 'notifications'})
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-overlay {
  min-width: 280px;
  background-color: #fff;
  right: 0;
  border-radius: 10px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%), 0px 1px 4px rgb(0 0 0 / 8%);
  .item.active {
    background-color: azure;
  }
}
</style>
