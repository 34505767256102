import { createRouter, createWebHistory } from 'vue-router';
import App from '@/apps/App.vue';

const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('./pages/Landing.vue'),
  },
  {
    path: '/app',
    name: 'app',
    component: App,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('./pages/dashboard/Dashboard.vue'),
      },
      {
        path: 'salons',
        name: 'salons-base',
        component: () => import('./components/dashboard/Base'),
        meta: {
          auth: {
            roles: 'admin_priti',
          },
        },
        children: [
          {
            path: '',
            name: 'salons',
            component: () => import('./pages/salons/ListFilter'),
          },
          {
            path: 'create',
            name: 'create-salon',
            component: () => import('./pages/salons/Create'),
          },
          {
            path: ':id',
            name: 'detail-salon',
            component: () => import('./pages/salons/Detail'),
          },
          {
            path: ':id/edit',
            name: 'edit-salon',
            component: () => import('./pages/salons/Edit'),
          },
        ],
      },
      {
        path: 'salon',
        name: 'salon-base',
        component: () => import('./components/dashboard/Base'),
        meta: {
          auth: {
            roles: 'admin_salon',
            redirect: { name: 'salons' },
          },
        },
        children: [
          {
            path: '',
            name: 'salon',
            component: () => import('./pages/salons/Detail'),
          },
          {
            path: 'edit',
            name: 'edit-salon-admin',
            component: () => import('./pages/salons/Edit'),
          },
        ],
      },
      {
        path: 'salon-services',
        name: 'salon-services-base',
        component: () => import('./components/dashboard/Base'),
        children: [
          {
            path: '',
            name: 'salon-services',
            component: () => import('./pages/salon-services/ListFilter'),
          },
          {
            path: 'create',
            name: 'create-salon-service',
            component: () => import('./pages/salon-services/Create'),
          },
          {
            path: ':id',
            name: 'detail-salon-service',
            component: () => import('./pages/salon-services/Detail'),
          },
          {
            path: ':id/edit',
            name: 'edit-salon-service',
            component: () => import('./pages/salon-services/Edit'),
          },
        ],
      },
      {
        path: 'categories',
        name: 'categories-base',
        component: () => import('./components/dashboard/Base'),
        children: [
          {
            path: '',
            name: 'categories',
            component: () => import('./pages/categories/ListFilter'),
          },
          {
            path: 'create',
            name: 'create-category',
            component: () => import('./pages/categories/Create'),
          },
          {
            path: ':id',
            name: 'detail-category',
            component: () => import('./pages/categories/Detail'),
          },
          {
            path: ':id/edit',
            name: 'edit-category',
            component: () => import('./pages/categories/Edit'),
          },
        ],
      },
      {
        path: 'barbers',
        name: 'barbers-base',
        component: () => import('./components/dashboard/Base'),
        children: [
          {
            path: '',
            name: 'barbers',
            component: () => import('./pages/barbers/ListFilter'),
          },
          {
            path: 'create',
            name: 'create-barber',
            component: () => import('./pages/barbers/Create'),
          },
          {
            path: ':id',
            name: 'detail-barber',
            component: () => import('./pages/barbers/Detail'),
          },
          {
            path: ':id/edit',
            name: 'edit-barber',
            component: () => import('./pages/barbers/Edit'),
          },
        ],
      },
      {
        path: 'invoices',
        name: 'invoices-base',
        component: () => import('./components/dashboard/Base'),
        children: [
          {
            path: '',
            name: 'invoices',
            component: () => import('./pages/invoices/ListFilter'),
          },
          {
            path: ':id',
            name: 'detail-invoice',
            component: () => import('./pages/invoices/Detail'),
          },
        ],
      },
      {
        path: 'admins',
        name: 'admins-base',
        component: () => import('./components/dashboard/Base'),
        children: [
          {
            path: '',
            name: 'admins',
            component: () => import('./pages/admins/ListFilter'),
          },
          {
            path: ':id',
            name: 'detail-admin',
            props: true,
            component: () => import('./pages/admins/Detail'),
          },
          {
            path: 'create',
            name: 'create-admin',
            component: () => import('./pages/admins/Form'),
          },
          {
            path: ':id/edit',
            name: 'edit-admin',
            props: true,
            component: () => import('./pages/admins/Form'),
          },
        ],
      },
      {
        path: 'notifications',
        name: 'notifications',
        props: true,
        component: () => import('./pages/notification/Notification'),
      },
      {
        path: 'report/salon',
        name: 'salon-report',
        props: true,
        component: () => import('./pages/report/SalonReport'),
      },
      {
        path: 'report/barber',
        name: 'barber-report',
        props: true,
        component: () => import('./pages/report/BarberReport'),
      },
      {
        path: 'qna',
        // name: 'qna-base',
        component: () => import('./components/dashboard/Base'),
        meta: {
          auth: {
            roles: 'admin_salon',
            redirect: { name: 'all_qna' },
          },
        },
        children: [
          {
            path: '',
            name: 'qna',
            component: () => import('./pages/qna/Detail'),
          },
        ],
      },
      {
        path: 'qna',
        name: 'qna-base',
        component: () => import('./components/dashboard/Base'),
        meta: {
          auth: {
            roles: 'admin_priti',
          },
        },
        children: [
          {
            path: '',
            name: 'all_qna',
            component: () => import('./pages/qna/ListFilter'),
          },
          {
            path: ':id',
            name: 'qna-salon',
            component: () => import('./pages/qna/Detail'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      auth: false,
    },
    component: () => import('./pages/auth/Login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('./pages/auth/Register.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./pages/auth/ForgotPassword.vue'),
  },
  {
    path: '/forgot-password/verify/:token',
    name: 'verify-forgot-password',
    props: true,
    component: () => import('./pages/auth/VerifyForgotPassword.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    props: true,
    component: () => import('./pages/auth/ResetPassword.vue'),
  },
  {
    path: '/term-and-condition',
    name: 'term-and-condition',
    meta: {
      auth: false,
    },
    component: () => import('./pages/TermAndCondition.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: {
      auth: false,
    },
    component: () => import('./pages/PrivacyPolicy.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('./pages/Error.vue'),
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('./pages/Access.vue'),
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notfound',
    component: () => import('./pages/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
