<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" :user="user" />

    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu
        :model="menu"
        :isAdmin="isAdministrator"
        :salonName="salon_name"
        :user="user"
        @menuitem-click="onMenuItemClick"
      />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
    
    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay"
        v-if="mobileMenuActive">
      </div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
import { useAuthRole } from '@/composables/auth';

export default {
  emits: ['change-theme'],
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
  setup() {
    const { isAdminPriti, getUser } = useAuthRole()

    return { isAdminPriti, getUser }
  },
  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [],
      salon_name: '',
      salon_status: null,
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      // this.$toast.removeAllGroups();
    },
    salon_status(newValue) {
      this.updateMenu(this.isAdminPriti(), newValue)
    },
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? 'images/logo-white.svg'
        : 'images/logo.svg';
    },
    isAdministrator() {
      let isAdmin = this.isAdminPriti()
      this.updateMenu(isAdmin);
      return isAdmin;
    },
    user() {
      const user = this.getUser();
      if (user && user.salon_id && user.salon_id !== 0) {
        this.getSalonName(user.salon_id)
        this.updateMenu(false);
      }
      return user;
    },
  },
  created() {
    document.title = process.env.VUE_APP_TITLE;
  },
  beforeUpdate() {
    if (this.mobileMenuActive) {
      this.addClass(document.body, 'body-overflow-hidden');
    } else {
      this.removeClass(document.body, 'body-overflow-hidden');
    }
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
            'gi'
          ),
          ' '
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
      }

      return true;
    },
    getSalonName(salonID = null) {
      if (!salonID) return
      
      this.$http
        .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/salons/${salonID}`)
        .then((response) => {
          if (response.status === 200) {
            this.salon_name = response.data.data.name;
            this.salon_status = response.data.data.status;
          }
        });
    },
    updateMenu(isAdminPriti, salonStatus = 0) {
      let customTabs = [
        {
          label: isAdminPriti ? 'Salon' : 'Profil Salon',
          icon: 'pi pi-fw pi-building',
          to: { name: isAdminPriti ? 'salons' : 'salon' },
        },
        isAdminPriti 
          ? {
            label: 'Kategori',
            icon: 'pi pi-fw pi-bookmark',
            to: { name: 'categories' },
          } : null,
      ];

      let approvedMenu = !isAdminPriti && (salonStatus === 0 || salonStatus === 2)
        ? []
        : [{
            label: 'Layanan Salon',
            icon: 'pi pi-fw pi-tags',
            to: { name: 'salon-services' },
          },
          {
            label: 'Pegawai',
            icon: 'pi pi-fw pi-building',
            to: { name: 'barbers' },
          },
          {
            label: 'Pesanan',
            icon: 'pi pi-fw pi-book',
            to: { name: 'invoices' },
          },
          {
            label: 'Tanya Jawab',
            icon: 'pi pi-fw pi-question',
            to: { name: isAdminPriti ? 'all_qna' : 'qna' },
          }];
      
      let reportMenu = !isAdminPriti 
        ? [{
          label: 'Laporan',
          items: [
            {
              label: 'Laporan Salon',
              icon: 'pi pi-fw pi-list',
              to: { name: 'salon-report' },
            },
            {
              label: 'Laporan Barber',
              icon: 'pi pi-fw pi-list',
              to: { name: 'barber-report' },
            },
          ],
        }] : [];

      this.menu = [
        {
          label: 'Home',
          items: [
            {
              label: 'Dashboard',
              icon: 'pi pi-fw pi-home',
              to: { name: 'dashboard' },
            },
            ...customTabs,
            ...approvedMenu,
          ],
        },
        ...reportMenu,
        {
          label: 'MANAJEMEN USER',
          items: [
            {
              label: 'Administrator',
              icon: 'pi pi-fw pi-users',
              to: { name: 'admins' },
            },
          ],
        },
      ]
    },
  },
};
</script>

<style lang="scss">
@import './App.scss';
@import 'Tailwind.css';
</style>
