<template>
  <div class="layout-menu-container">
    <div class="profile relative tw-mb-4" :style="{'background-image': `url(${form.image ? form.image : user.photo_profile})`}">
      <i class="pi pi-user" v-if="!form.image && !user.photo_profile"></i>
      <div href="#" class="upload loading align-items-center justify-content-center tw-flex-col" v-if="form.loading">
        <i class="pi pi-spin pi-spinner tw-text-white tw-text-3xl"></i>
      </div>
      <div href="#" class="upload align-items-center justify-content-center tw-flex-col" v-else>
        <i class="pi pi-pencil tw-text-white tw-text-3xl"></i>
        <label class="tw-text-white tw-font-semibold tw-text-xl tw-cursor-pointer">Edit</label>
        <input type="file" class="" @input="uploadFile">
      </div>
    </div>
    <div class="profile-info text-center mb-4">
      <h6 class="tw-font-semibold mb-1">{{ user ? user.email : "" }}</h6>
      <h6 class="tw-text-gray-400 tw-text-sm">
        <span v-if="isAdminPriti()"> Admin Salonial </span>
        <span v-else> Admin {{ salonName }} </span>
      </h6>
    </div>
    <AppSubmenu
      :items="model"
      class="layout-menu"
      :root="true"
      @menuitem-click="onMenuItemClick"
      @keydown="onKeyDown"
    />
  </div>
</template>

<script>
import { useAuthRole } from "@/composables/auth";
import AppSubmenu from "./AppSubmenu";

export default {
  setup() {
    const { isAdminPriti } = useAuthRole();
    return { isAdminPriti };
  },
  props: {
    model: Array,
    user: Object,
    salonName: String,
  },
  components: {
    AppSubmenu: AppSubmenu,
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
  data() {
    return {
      form: {
        loading: false,
        image: '',
      },
    }
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onKeyDown(event) {
      const nodeElement = event.target;
      if (event.code === "Enter" || event.code === "Space") {
        nodeElement.click();
        event.preventDefault();
      }
    },
    bannerImage() {
      return this.$appState.darkTheme
        ? "images/banner-primeblocks-dark.png"
        : "images/banner-primeblocks.png";
    },
    uploadFile(event) {
      let files = event.target.files
      if (!files.length) return
      
      let reader = new FileReader()
      let formData = new FormData()
      let vm = this

      reader.onload = (e) => {
        vm.form.image = e.target.result
        vm.form.loading = true

        formData.append('image', files[0])

        vm.$http
          .post(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user/${vm.user.user_id}/admin/upload-image`, formData)
          .then(response => {
            vm.form.loading = false
            vm.$toast.add({
              severity: 'success',
              closable: true,
              summary: 'Success',
              detail: response.data.message,
              life: 3000,
            })
          })
          .catch(e => {
            vm.$toast.add({
              severity: 'error',
              closable: true,
              summary: 'Error',
              detail: e.response.data.message,
              life: 3000,
            })
          })
      }
      reader.readAsDataURL(files[0])
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  width: 120px;
  height: 120px;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  > i {
    font-size: 61px;
    color: grey;
  }
  .upload {
    display: none;
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  &:hover .upload,
  .upload.loading {
    position: absolute;
    display: flex;
    background-color: rgba(0, 0, 0, .3);
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
  }
  input[type=file],
  input[type=file]::-webkit-file-upload-button {
    cursor: pointer; 
  }
}
</style>
