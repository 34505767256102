import { useAuth } from "@websanova/vue-auth";
import { useToast } from "primevue/usetoast";
import { onBeforeMount, ref } from "vue";

export function useCustomAuth() {
    const auth = useAuth()
    const toast = useToast()

    const login = async ({email, password, recaptcha_resp}) => {
        try {
            const {data} = await auth.login({
                data: {
                    'email': email,
                    'password': password,
                    'g-recaptcha-response': recaptcha_resp,
                },
                remember: false,
                staySignedIn: false,
                fetchUser: false,
            })
            if (data.code === 200) {
                toast.add({
                    severity: 'success',
                    summary: 'Login Berhasil',
                    life: 3000
                });
                auth.token(null, data?.data.access_token, false)
                await auth.fetch({})
            } else {
                toast.add({
                    severity: 'error',
                    summary: 'Authentication Failed!',
                    detail: 'Email/Password invalid!',
                    life: 2000
                });
            }
        } catch (e) {
            if (e?.code === 'ERR_BAD_REQUEST') {
                const {data} = e.response
                toast.add({
                    severity: 'error',
                    summary: 'Login Gagal',
                    detail: data.message,
                    life: 2000
                });
                return
            }
            toast.add({
                severity: 'error',
                summary: 'Login Gagal',
                detail: e.response.data.message,
                life: 2000
            });
        }
    }

    const logout = () => {
        auth.logout({makeRequest: false, redirect: {name: 'login'}})
    }

    return {login, logout}
}

export function useAuthRole() {
    const auth = useAuth()
    const salonId = ref(null)

    onBeforeMount(() => {
        salonId.value = getSalonId()
    })

    const getSalonId = () => auth.user() ? auth.user().salon_id : null

    const getUser = () => auth.user()

    const isAdminPriti = () => auth.check('admin_priti')

    const isAdminSalon = () => auth.check('admin_salon')

    const isAuthenticated = () => auth.isAuthenticated()

    return {isAdminPriti, isAdminSalon, getSalonId, getUser, salonId, isAuthenticated}
}